import 'scroll-behavior-polyfill';


/**
***  指定したプロパティを要素から取得する  */
const getStyle = (el, styleProp) => {
  let defaultView = ( el.ownerDocument || document ).defaultView;
  styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
  return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
};


/**
***  slideUp  */
const slideup = (el, duration = 300) => {
  el.style.height = el.offsetHeight + "px";
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  setTimeout(() => {
    el.style.display = "none";
    el.style.removeProperty("height");
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
  }, duration);
};


/**
***  slideDown  */
const slidedown = (el, duration = 300) => {
  el.style.removeProperty("display");
  let display = window.getComputedStyle(el).display;
  if (display === "none") {
    display = "block";
  }
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.height = height + "px";
  el.style.removeProperty("padding-top");
  el.style.removeProperty("padding-bottom");
  el.style.removeProperty("margin-top");
  el.style.removeProperty("margin-bottom");
  setTimeout(() => {
    el.style.removeProperty("height");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
  }, duration);
};


/**
***  slideToggle  */
const slidetoggle = (el, duration = 300) => {
  if (window.getComputedStyle(el).display === "none") {
    return slidedown(el, duration);
  } else {
    return slideup(el, duration);
  }
};


/**
***  Page loading  */
const loadingWrapper = document.getElementById('loadingWrapper');
window.onload = function(){
  setTimeout(function() {
    loadingWrapper.classList.add('disabled');
  }, 100);
}


/**
***  Get classname  */
const getClassNames = function(element) {
	return (element) ? element.className.split(' ') : [];
};


/**
***  burger  */
(function(){
  const burger = document.getElementById('jsBurger');
  // ない場合はなにもしない
  if( !burger ){ return; }
  burger.addEventListener('click', function(){
    const activeClassName = 'visible-mobile-menu';
    const classes = getClassNames(document.body);
    for ( let i = 0; i < classes.length; i++) {
      if( classes[i].length > 0 && classes[i] !== activeClassName){
        document.body.classList.remove(classes[i]);
      }
    }
    document.body.classList.toggle(activeClassName);
  });
}());


/**
***  search  */
(function(){
  const search = document.getElementById('jsSearch');
  // ない場合はなにもしない
  if( !search ){ return; }
  search.addEventListener('click', function(){
    const activeClassName = 'visible-input-search';
    const classes = getClassNames(document.body);
    for ( let i = 0; i < classes.length; i++) {
      if( classes[i].length > 0 && classes[i] !== activeClassName){
        document.body.classList.remove(classes[i]);
      }
    }
    document.body.classList.toggle(activeClassName);
  });
  //範囲外クリック
  const openMenuClass = 'visible-input-search';
  const headerClass = 'construct-header';
  document.addEventListener('click', (e) => {
    const ww = window.innerWidth;
    const isActive = document.querySelector('body').classList.contains( openMenuClass );
    if(!e.target.closest( ' .' + headerClass) && ww < 992 && isActive === true) {
      document.body.classList.remove( openMenuClass );
    }
  });
}());


/**
***  pagetop  */
(function(){
  const trigger = document.querySelectorAll('.js-pagetop');
  if( !trigger ){ return; }
  const defaultPagetop = document.querySelector('.pagetop');
  const pagetopActiveClass = 'pagetop-active';
  // スクロール
  window.addEventListener('scroll', function(e){
    if (window.scrollY > 100) {
      defaultPagetop.classList.add(pagetopActiveClass);
    } else {
      defaultPagetop.classList.remove(pagetopActiveClass);
    }
  });
  // クリックイベント（共通）
  for ( let i = 0; i < trigger.length; i++ ){
    trigger[i].addEventListener('click', function(){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
  };
}());


/**
***  accordion  */
(function(){
  // アコーディオンの親要素のクラス名
  const accordionParentClassName = '.accordion';
  // アコーディオンのクリックイベントを行うクラス名
  const triggerClassName = '.js-accordion-trigger';
  // アコーディオンを開くためのクラス名
  const activeClassName = 'active';
  // アコーディオンのトリガーをすべて取得する
  const acc = document.querySelectorAll(triggerClassName);
  // クリックイベント
  for ( let i = 0; i < acc.length; i++ ){
    acc[i].addEventListener('click', function(e){
      // アクティブクラスが付いているかチェックする
      const isActive = this.classList.contains(activeClassName);

      // // クリックした要素のアコーディオンの親要素を取得する
      // const parentAccordion = this.closest(accordionParentClassName);
      // // すべてのアコーディオンを閉じる
      // const allAccordions = parentAccordion.querySelectorAll(triggerClassName);
      // for ( let j = 0; j < allAccordions.length; j++) {
      //   // Remove active class from section header
      //   allAccordions[j].classList.remove(activeClassName);
      //   // Remove the max-height class from the panel to close it
      //   const panel = allAccordions[j].nextElementSibling;
      //   const maxHeightValue = getStyle(panel, 'maxHeight');

      //   if (maxHeightValue !== '0px') {
      //     panel.style.maxHeight = null;
      //   };
      // };

      // アクティブクラスの付け替え
      isActive ? this.classList.remove(activeClassName) : this.classList.add(activeClassName);
      // アコーディオンの開閉処理
      const panel = this.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      };
    });
  }
  //カレント変更
  const url = new URL(window.location.href);
  const param = url.searchParams.get('accordion');
  if(param !== null){
    const trigger = document.getElementById(param);
    if(trigger){
      trigger.classList.add('active');
      const panel = trigger.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      };
    }
  }
}());


/**
***  tab  */
(function(){
  // タブ処理を行う要素のクラス名
  const tabsClass            = 'js-tab';
  const viewsClass           = 'js-contents';
  const currentClassName     = 'js-tab-current';
  const currentViewClassName = 'js-tab-current-view';
  // タブ処理を行う要素の取得
  const tabs = document.getElementsByClassName(tabsClass);
  // ない場合はなにもしない
  if( !tabs ){ return; }
  // Click event
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function(){
      // データ属性を取得する
      const view = this.dataset.view;
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName);
      // クリックしたタブの親要素を取得する
      const tabsParent = this.parentNode.parentNode.parentNode;
      // 他のタブ要素に影響しないようにクリックしたタブ要素だけに絞る
      const tabsChild = tabsParent.getElementsByClassName(tabsClass);
      // コンテンツの要素を取得する
      const allContents = tabsParent.getElementsByClassName(viewsClass);

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabsChild.length; j++ ){
          tabsChild[j].classList.remove(currentClassName);
        };
        this.classList.add(currentClassName);
      };
      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName);
      };
      //
      document.getElementById(view).classList.add(currentViewClassName);
    });
  };
  //カレント変更
  const url = new URL(window.location.href);
  const param = url.searchParams.get('tab');
  if(param !== null){
   const allLabels      = document.querySelectorAll('.' + tabsClass);
   const targetLabel    = document.querySelector('[data-view="' + param + '"]');
   const allContents    = document.querySelectorAll('.' + viewsClass);
   const targetContent  = document.getElementById(param);
   allLabels.forEach((value) => {
    value.classList.remove(currentClassName);
    targetLabel.classList.add(currentClassName);
   });
   allContents.forEach((value) => {
    value.classList.remove(currentViewClassName);
    targetContent.classList.add(currentViewClassName);
   });
  }
}());


/**
***  paramAnchor  */
(function(){
  const url = new URL(window.location.href);
  const param = url.searchParams.get('anchor');
  const paramAnchor = function(){
    if(param === null){ return; };
    const pageID = document.getElementById(param);
    if( !pageID ){ return; };
    const rect = pageID.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const position = rect.top + scrollTop;
    //ヘッダーの高さ
    const header = document.getElementsByTagName('header');
    const heightHeader = header[0].offsetHeight;
    // スクロール処理
    window.scrollTo({
      top: position - heightHeader,
      left: 0,
      behavior: 'smooth'
    }); 
  };
  window.addEventListener('load', function() {
    paramAnchor();
  })
}());


/**
***  ページ内リンク  */
(function(){
  const elm = document.querySelectorAll('.js-page-anchor');
  // ない場合はなにもしない
  if( ! elm.length ){ return; };
  // イベント
  for ( let i = 0; i < elm.length; i++) {
    elm[i].addEventListener( 'click', function(e){
      e.preventDefault();
      const href = this.getAttribute('href');
      // ページ内リンクでない場合は何もしない
      if( href.slice(0, 1) != '#' ){ return; };
      // リンク先の要素を取得
      const pageID = document.getElementById(href.slice(1));
      // ない場合はなにもしない
      if( !pageID ){ return; };
      const rect = pageID.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const position = rect.top + scrollTop;
      //ヘッダーの高さ
      const header = document.getElementsByTagName('header');
      const heightHeader = header[0].offsetHeight;
      // スクロール処理
      window.scrollTo({
        top: position - heightHeader,
        left: 0,
        behavior: 'smooth'
      });
    });
  }
}());


/**
***  別ページのアンカー  */
(function(){
  const outerAnchor = function(){
    const hash = location.hash;
    if( ! hash.length ){ return; };
    const pageID = document.getElementById(hash.slice(1));
    if( !pageID ){ return; };
    const rect = pageID.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const position = rect.top + scrollTop;
    //ヘッダーの高さ
    const header = document.getElementsByTagName('header');
    const heightHeader = header[0].offsetHeight;
    // スクロール処理
    window.scrollTo({
      top: position - heightHeader,
      left: 0,
      behavior: 'smooth'
    }); 
  };
  window.addEventListener('load', function() {
    outerAnchor();
  })
}());


/**
***  Grobal Menu Event  */
(function(){
  // グローバルナビゲーションの要素
  const globalNav = document.getElementById('globalNav');
  // すべてのトリガーを取得する
  const allTrigger = globalNav.querySelectorAll('.js-trigger');
  // 幅が lg 以上のビューポートをターゲットとするメディアクエリを作成
  const mediaQuery = window.matchMedia('(min-width: 992px)');
  // アクティブクラスが付いているかチェックする
  const activeClassName = 'active';
  // ホバー時に付与するクラス名
  const hoverClassName = 'hover';
  // Window width
  let memoryWindowWidth = window.innerWidth;
  // イベント
  allTrigger.forEach( elm => {
    // クリックイベント
    elm.addEventListener('click', function(t){
      // PCでの処理
      if( mediaQuery.matches ){
        return;
      } else {
        // モバイルでの処理
        t.preventDefault();
        // アクティブ判定
        const isActive = this.parentNode.classList.contains(activeClassName);

        // 一度、すべてのメニューを閉じる
        allTrigger.forEach( child => {
          // 高さを取得する要素（クリックした要素の子メニュー）
          const panel = child.nextElementSibling;
          //const heightValue = getStyle(panel, 'height');
          if( child !== this ){
            child.parentNode.classList.remove(activeClassName);
            panel.style.height = null;
          }
        });
        // アクティブクラスの処理
        isActive ? this.parentNode.classList.remove(activeClassName) : this.parentNode.classList.add(activeClassName);
        // 子メニューの開閉処理
        // Toggle the panel element
        const panel = this.nextElementSibling;
        const heightValue = getStyle( panel, 'height' );
        if (heightValue !== '0px') {
          panel.style.height = null;
          return;
        }
        panel.style.height = panel.scrollHeight + 'px';
      }
    });
    // PC時のホバー処理
    elm.parentNode.addEventListener( 'mouseenter', () => {
      if( mediaQuery.matches ){
        elm.parentNode.classList.add( hoverClassName );
        elm.nextElementSibling.style.height = elm.nextElementSibling.scrollHeight + 'px';
      }
    });
    elm.parentNode.addEventListener( 'mouseleave', () => {
      if( mediaQuery.matches ){
        elm.parentNode.classList.remove( hoverClassName );
        elm.nextElementSibling.style.height = null;
      }
    });
  });
  // window リサイズでメニューをクリアにする処理
  window.addEventListener( 'resize', function(){
    if( memoryWindowWidth !== window.innerWidth ){
      // window の width を取得し直す
      memoryWindowWidth = window.innerWidth;
      allTrigger.forEach( el => {
        // 子メニューを閉じる
        el.parentNode.classList.remove(activeClassName);
        const panel = el.nextElementSibling;
        panel.style.height = null;
      });
      // バーガーを閉じる
      this.document.body.classList.remove('visible-mobile-menu');
    }
  });
  const openMenuClass = 'visible-mobile-menu';
  const headerClass = 'construct-header';
  //範囲外クリック
  /*document.addEventListener('click', (e) => {
    const ww = window.innerWidth;
    const isActive = document.querySelector('body').classList.contains( openMenuClass );
    if(!e.target.closest( ' .' + headerClass) && ww < 992 && isActive === true) {
      document.body.classList.remove( openMenuClass );
      for ( let i = 0; i < allTrigger.length; i++ ){
        const panel = allTrigger[i].nextElementSibling;
        allTrigger[i].parentNode.classList.remove(activeClassName);
        panel.style.height = null;
      };
    }
  });*/
}());


/**
***  高さの取得  */
const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  const elm = document.querySelector('.construct-nav');
  elm.style.setProperty('--vh', `${vh}px`);
}
// 画面のサイズ変動があった時に高さを再計算する
window.addEventListener('resize', setFillHeight);
// 初期化
setFillHeight();


/**
***  sidebar */
(function(){
  // sidebarの親
  const parentSelector   = '.side-column';
  // current時に付与するクラス名
  const currentClassname = 'active';
  // triggerのクラス名
  const triggerClassName = 'js-trigger-side';
  // triggerの親のクラス名
  const parentClassName = 'js-trigger-parent';　
  //展開時のクラス名
  const activeClassName = 'open';
  // trigger要素
  const toggle = document.getElementsByClassName( triggerClassName );
  // current処理
  const href = location.href,
        protocol = location.protocol,
        host = location.host,
        path = href.replace(protocol + '//' + host, ''),
        parentElm = document.querySelector(parentSelector),
        links = parentElm.querySelectorAll('a');
  links.forEach((element) => {
    const link = element.getAttribute('href');
    if( formatPath(link) === formatPath(path)){
      element.classList.add(currentClassname);
      if(element.closest('.' + parentClassName)){
        const parentTrigger = element.closest('.' + parentClassName);
        parentTrigger.querySelector('.' + triggerClassName).classList.add(activeClassName);
        parentTrigger.querySelector('.' + triggerClassName).nextSibling.style.display = 'block';
      }
    }
  })
  function formatPath(el){
    const ass = el.substring(el.lastIndexOf("/") + 1, el.length);
    if( ass.length && ass.indexOf('index.php') >= 0 ){
      return el.replace('index.php', '');
    }
    else if( ass.length && ass.indexOf('index.html') >= 0 ){
      return el.replace('index.html', '');
    }
    else if ( ass.length && ass.indexOf('.php') < 0 && ass.indexOf('.html') < 0 && ass.indexOf('?') < 0 && ass.indexOf('#') < 0) {
      return el + '/';
    }
    else{
      return el;
    }
  };
  // toggleアクション
  if ( toggle !== null && toggle !== undefined  ) {
    for ( let i = 0; i < toggle.length; i++ ){
      toggle[i].addEventListener('click', function( e ){
        e.preventDefault();
        //アクティブ判定                                      
        const isActive = this.classList.contains( activeClassName );
        //クリックした要素の子メニュー
        const panel = this.nextElementSibling;
        //クリックした要素の親トグル
        const parent = this.closest( '.' + parentClassName ).querySelectorAll( '.' + triggerClassName )[0];
        //全トグルリスト
        const allToggles = document.getElementsByClassName(triggerClassName);
        //一度全部閉じる
        for ( let j = 0; j < allToggles.length; j++ ) {
          //子メニュー
          const panel = allToggles[j].nextElementSibling;
          //除外条件
          if( allToggles[j] !== toggle[i] && allToggles[j] !== parent ){
            slideup( panel, 300 );
            allToggles[j].classList.remove( activeClassName );
          }
        };
        //開閉
        if( isActive ){
          this.classList.remove( activeClassName );
          slideup( panel, 300 );
        }else{
          this.classList.add( activeClassName );
          slidedown( panel, 300 );
        }
      });
    };
    //クリア
    // window.addEventListener( 'resize', function() {
    //   if ( toggle !== null && toggle !== undefined  ) {
    //     for ( let i = 0; i< toggle.length; i++ ){
    //       toggle[i].classList.remove( activeClassName );
    //       toggle[i].parentNode.nextElementSibling.style.display = '';
    //     }
    //   }
    // });
  };
}());

/**
***  読み込まれているかの試し  */
/*alert('Hello World');
(function(){
  alert('Hello World');
}());*/